import React from 'react'

import DefaultFormattedMessage from '../utils/DefaultFormattedMessage'
import {
  address,
  container,
  footer,
  link,
  links,
  linksTitle,
  location,
  social,
  voorwaarden,
  acyear,
} from './Footer.module.css'
import LocalLink from './LocalLink'
import SocialMedia from './SocialMedia'

const Footer = () => {
  return (
    <footer className={footer}>
      <div className={container}>
        <address className={address}>
          <strong className={location}>Devine</strong>
          <br />
          Dam 2a
          <br />
          8500 Kortrijk
          <br />
          Belgium
        </address>
        <address className={address}>
          <strong className={location}>Howest</strong>
          <br />
          Marksesteenweg 85
          <br />
          8500 Kortrijk
          <br />
          Belgium
        </address>
        <section className={links}>
          <h2 className={linksTitle}>Links</h2>
          <ul>
            <li>
              <a className={link} href="https://www.schoolofartsgent.be/">
                Kask
              </a>
            </li>
            <li>
              <a
                className={link}
                href="https://www.howest.be/nl/studeren/studentenvoorzieningen"
              >
                Stuvo
              </a>
            </li>
            <li>
              <a className={link} href="https://www.schoolofartsgent.be/">
                Sofa
              </a>
            </li>

            <li>
              <a className={link} href="https://www.howest.be">
                Howest
              </a>
            </li>
          </ul>
        </section>

        <div className={social}>
          <SocialMedia inverted />
        </div>

        <LocalLink to="/privacy" className={voorwaarden}>
          <DefaultFormattedMessage id="main.footer.voorwaarden" />
        </LocalLink>

        <p className={acyear}>
          <DefaultFormattedMessage id="main.footer.acyear" />
        </p>
      </div>
    </footer>
  )
}

export default Footer
