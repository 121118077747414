module.exports = {
  'curriculum.browser.description': '',
  'curriculum.browser.title': 'Curriculum - Devine Kask Conservatory',
  'curriculum.main.jobroles': 'Job Roles',
  'curriculum.intro.title': 'The three pillars of Devine',
  'curriculum.intro.p':
    "As a Deviner, you use your design, development and ux skills to (re)shape the future of digital experiences. Expand your knowledge within Devine's three core skills. As you reach your final year, personalise your curriculum by choosing your expertise.",
  'curriculum.intro.button1': 'Experience design',
  'curriculum.intro.button2': 'Visual design',
  'curriculum.intro.button3': 'Development',
  'curriculum.samen.title':
    'Knowledge is treasure but practice is the key to success.',
  'curriculum.studpunt.title': 'The program',
  'curriculum.studpunt.intro':
    'Our program adapts to the current trends. The following schedule is subject to changes.',
  'curriculum.studpunt.y1': 'First year',
  'curriculum.studpunt.y2': 'Second year',
  'curriculum.studpunt.y3': 'Third year',
  'curriculum.samen.p':
    '‘Integration’ is a module that focuses on combining these three pillars. The individual skills you’ve learnt across the three different pillars are brought into effective action. Design, development and ux are applied in your very own creations which ensures that intricate theory is practiced until eventually it can be performed without a second thought. ',
  'curriculum.samen.button': 'Integration',
  'curriculum.design.title': 'Visual design',
  'curriculum.design.subtitle': 'See, hear and create.',
  'curriculum.design.p1':
    'He who designs learns to look at usual things with unusual eyes.” Being able to translate the world into your own visual creations is the very first step you take in the design module. Learn how to master design tools from the Adobe Creative Suite. Designing alters the way you approach challenges. This boost in creativity is irreversible and the ability to think conceptually will inspire original designs for years to come. In your first year you pick up brainstorming techniques to elevate your own natural abilities to create fresh ideas. You acquaint yourself with designers in the field and use this inspiration to experiment with the basics of typography, visuals and composition. As a Deviner you go beyond digital limitations and broaden your horizons with trips to art galleries, familiarise you with art history and practicing drawing techniques. Additionally, you learn to communicate visually using the art of storytelling to captivate your audience.',
  'curriculum.design.subtitle2': 'Designing digital products.',
  'curriculum.design.p2':
    'In the 2nd year you are introduced to the needs of clients and customers and incorporate them in the design process. You fluently translate a brief and determine what content should be displayed at which time to cater to your target audience.{br}Simultaneously with the development course, you gain the ability to design for a wide array of screen sizes. Touchscreens provide new design challenges but you manage to create a consistent experience across the line. Digital design becomes a stepping stone to explore design thinking, design systems and the diverse range of platforms. Congratulations, you are not merely a web designer but well on your way to becoming a future-proof digital product designer.   ',
  'curriculum.design.subtitle3': 'Motion graphics & 3D.',
  'curriculum.design.p3':
    'In Motion classes you add delight to your designs. This (motion) design module will plunge you into the basics of After Effects and allow you to create modern motion design and expressive animations that will move your audience into action. There is more to it than meets the eye: ideation, planning, creating storyboards, stylesframes... Details such as the stylization, transitions, match cuts and secondary  animations elevate the final animation to a level of excellence .',
  'curriculum.design.p4':
    'By choosing the expert in design you immerse yourself fully in the world of visual design. You focus on selecting a single design skill and go to infinity and beyond to master your craft. A deep understanding is formed that design holds the power to draw attention and enact positive change in response to social issues around the world. You will be leading your own design project with a personal coach offering you support and assistance along the way to help you achieve your desired goals. In the meantime, Blender will be put on your radar and you’ll delve into the complex world of of 3d animation to become familiar with texturing, lighting and rendering a project. You are encouraged to visit expert talks that relate to your field of expertise. At the end of the year a panel of external judges from the industry will review your final work.  ',
  'curriculum.dev.title': 'Development',
  'curriculum.dev.subtitle1': 'Building code.',
  'curriculum.dev.p1':
    "In development you train yourself in problem-solving thinking using different programming languages. In the first year we apply the principle of pair programming. This means you work side by side with one of your classmates with the major benefit that you can review each other's work, break down potential barriers and ultimately achieve a higher quality of code. You start by defining the structure of your site in HTML, then by shaping it in CSS to finally adding interactivity via JavaScript. Next we focus on building web applications that run a database. After completing your first year of Devine, you’ll be able to create your own social network and webshop.",
  'curriculum.dev.subtitle2': 'Programming in teams.',
  'curriculum.dev.p2':
    'During the second year of development we factor in the different types of devices you can develop a website or app for. You learn how to build responsive websites, ensuring that your website has a fluid and flexible layout which adjusts according to screen size so your creation looks perfect on any device. It will gradually become important to utilise the right tools and implement techniques to help you manage your increasingly more complex projects. Effective teamwork is key. In the final development class of your 2nd year, you are building for the next-generation of user interfaces. The use of touch, voice and gestures are only the tip of the iceberg. Together we explore the landscape to create high-performance and user-friendly interfaces for web applications and let them communicate with a data service. You learn how to quickly create prototypes and then test them with your users. ',
  'curriculum.dev.subtitle3': 'Creative with code.',
  'curriculum.dev.p3':
    'In the final year of development, you are ready to experiment to your hearts content. With the knowledge you have obtained over the years, you can create without constraints using Arduinos, WebG, Machine Learning and real time communication. Physical installations are as much part of a potential solition as your digital ideas. These installations could be featured on festivals or in musea. Utilise your programming knowledge to create a real-life experience. This can range from anything such as an action-packed game to a visual show via light projections on buildings. The development experts participate in hackathons and continue to focus on their personal project. Similarly to the experts from design and research modules, you will be given full freedom to flesh out your personal development project. Thus giving you the opportunity to fully explore the subject matter of your choice, whether that’s on the subject of Artificial Intelligence to Full Stack Web Development. ',
  'curriculum.research.title': 'Experience design',
  'curriculum.research.subtitle1': 'Human centred approach. ',
  'curriculum.research.p1':
    'The core of everything we do lies in the ability to critically assess what people truly need. In the experience design module you partake in active workshops to learn how to place yourself in the shoes of the end user. The aim is to become ux designers that create value by observing people, analysing what they do and ultimately communicating your findings in a paper which includes user journey maps, content matrix, flowcharts, and wireframes. The end result is a detailed plan of a digital product.  ',
  'curriculum.research.subtitle2': 'Collecting data.',
  'curriculum.research.p2':
    'Seek to improve your knowledge in the second year and choose a self-imposed topic or challenge. Learn how to formulate a strong research question to assist you in your quest. This helps you cast a critical eye over society and discover opportunities for digital innovation. Step into the world to gather first hand data. Can you discover the hidden patterns and themes emerging from your dataset? Your findings will be directly applicable when forming your own design principles. Additionally, strengthen your skills in creating flowcharts and transform your (paper) wireframes into interactive prototypes.',
  'curriculum.research.subtitle3': 'Investigating the future.',
  'curriculum.research.p3':
    'Can’t get enough of experience design? Interested in further lectures in human-computer interaction? Wonderful, don’t hesitate to take the expert course in your final year and initiate your passion project. A mentor personally guides you in your individual exploration and investigation. ',
  'curriculum.integr.title': 'Integration',
  'curriculum.integr.p1':
    "In Devine we believe that the learnt knowledge and skills should benefit the entire society. That's why the students give back to the community during the integration weeks. The commercial and public sector, social and cultural institutions, ... must all be able to experience the creativity and innovativeness of our students. In the integration module, students therefore work together with a client on real cases and issues. In this module you combine your acquired skills and knowledge to develop digital products, services or systems that meet the ever-changing expectations of your target group. You think about how you can inform your audience in an objective manner, create awareness or perhaps even change behavior. You think holistically about the added value and sustainability of your digital solution for the 'wicked problems' of our society.",
  'curriculum.integr.p2':
    'The integration module bundles the skills that you have learned in Design, Development and UX. You take on the real challenges at the end of each semester.You work on the project, individually or in groups, in the stimulating creative environment of our campus. The traditional forms of teaching give way to personalized feedback. You sit together with your teachers and regularly check your progress.',
  'curriculum.integr.subtitle': 'What can you expect?',
  'curriculum.integr.p3':
    'While the first year revolves around asking for feedback on your ideas with your teachers, in the final year you sell them as if your teachers were the real customers. Integration gives you the opportunity to make your own planning, experiment and take ownership of your project from a to z. The theoretical moments make way for relevant topics related to your project, such as talking to trend watchers, teamwork workshops and learning how to set up the perfect elevator pitch. The bachelorproof is the ultimate integration project. During this project,  you work on a concrete research case from the professional field. This is your time to shine and show what you are made of.Your work is presented to an open jury, which in turn is witnessed by prominent companies looking to hire young potential. The final piece of your education and the proverbial cherry on the Devine cake is of course your internship in which you work for 3 months in a company of your choice.',
  'curriculum.integr.buttoninternship': 'Watch the internshipreel',
  'curriculum.integr.subtitle2': 'International exchange program',
  'curriculum.integr.p4':
    "In the 2nd year, we join forces with the Rotterdam University of Applied Sciences and work on an integration project for four weeks. You work in small interdisciplinary teams with the Rotterdam students for unique clients such as Opera Ballet Flanders, Museum of Fine Arts Ghent, Museum Boijmans Van Beuningen, ... During the first week, we travel across national borders and stay in Rotterdam. Together with your interdisciplinary team, you look for innovative solutions tailored to the client's brief. At the end of the exchange, the Rotterdam students spend a week on our campus, and you focus your strength to build a final prototype. These integration weeks are the perfect breeding ground for the Bachelor's thesis and it will slowly prepare you for working in the professional field.",
  'curriculum.integr.buttonrotterdam': 'Rotterdam aftermovie',
}
