module.exports = {
  'home.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'home.browser.title': 'Devine - Kask Conservatorium',
  'home.intro.baseline':
    '<b>Digital Design & Development is een volwaardige bachelor van 3 jaar aan KASK & Conservatorium, de school of arts van HOGENT en Howest.</b>',
  'home.intro.p1':
    'Als Deviner gebruik je design-, development- en ux skills om online ervaringen vandaag én in de toekomst vorm te geven. Ons hele curriculum is gesteund op deze 3 futureproof skills.',
  'home.intro.p2':
    'In deze sector is Devine dé referentie voor toptalent. Eens afgestudeerd, ga je meteen aan de slag als Experience Designer, Front-end Developer, Interaction Designer, Motion Designer, Full Stack Developer, Mobile Developer of kortweg Deviner.',
  'home.intro.button': 'Hoe inschrijven?',
  'home.succes.title': 'Succesverhalen van alumni',
  'home.succes.p':
    'Devine sluit je af met een stage. Je werkt 3 maanden in één van de nationale of internationale topbedrijven uit de creatieve digitale sector.',
  'home.succesvideo.lieselottitle': 'Bekijk het verhaal van Lieselot Verheye',
  'home.succesvideo.glenntitle': 'Bekijk het verhaal van Glenn Fellows',
  'home.succesvideo.kevintitle': 'Bekijk het verhaal van Kevin Meyvaert',
  'home.succes.morealumni': 'Meer alumni op linkedin',
  'home.studwerk.title': 'Werk en leven van studenten',
  'home.studwerk.p':
    'Ontdek studentenprojecten op de Devine Behance pagina. Onze studenten dienen zelf de projectresultaten waar ze trots op zijn in op deze account. Misschien staat jouw werk hier binnenkort ook tussen? ',
  'home.studwerk.button': 'Studentwork op Behance',
  'home.curriculum.title': 'Ons curriculum',
  'home.des.title': 'visual design',
  'home.des.p':
    'Creativiteit en beleving staan hier centraal. Je doorloopt het hele spectrum van digital design. Dit gaat van visual design, waar je inzet op branding, iconografie en typografie tot motion graphics waar jouw animaties alles in beweging zetten.',
  'home.des.button': 'Meer over visual design',
  'home.des.img': '',
  'home.dev.title': 'development',
  'home.dev.p':
    'Het mooie aan een Deviner zijn is dat je jouw projecten van A tot Z kan afwerken. In development zet je jouw technische vaardigheden aan het werk en tover je jouw design om in code. Je leert de code van je apps en websites voor en achter het scherm functioneel inzetten. ',
  'home.dev.button': 'Meer over development',
  'home.dev.img': '',
  'home.res.title': 'experience design',
  'home.res.p':
    'We willen betekenisvolle  en toekomstgerichte producten ontwerpen. Daarvoor stemmen we af met onze omgeving. We ontrafelen problemen, bestuderen gedrag en kijken naar de belangrijkste menselijke drijfveren. Dit geeft je een sterke basis voor het ontwerpen en ontwikkelen van een passende oplossing.',
  'home.res.button': 'Meer over experience design',
  'home.dev.img': '',
}
