module.exports = {
  'praktisch.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'praktisch.browser.title': 'Practical - Devine Kask Conservatory',
  'praktisch.nav.title1': 'How to apply to Devine',
  'praktisch.nav.title2': 'Application Essentials',
  'praktisch.nav.title3': 'Exchange students',
  'praktisch.apply.title': 'How to apply to Devine',
  'praktisch.apply.p1':
    "Devine is a 3-year bachelor's programme of 180 ECTS fully taught in English that focuses on the intersection of design, development and research. To start Devine you must have graduated from secondary school and obtained proof of proficiency in the English language. The application processing fee is 50 EUR and is non refundable.",
  'praktisch.apply.p2': 'Ready to take the next step?',
  'praktisch.apply.button': 'Apply Now',
  'praktisch.3step.title': 'Three Step Application Process',
  'praktisch.3step.p1':
    'Candidates wishing to study at Devine must fill out the online <appliform>student application form.</appliform>',
  'praktisch.3step.p2':
    'You will then be asked to <legalise>legalise</legalise> and send over your <b>diplomas/certificates and transcripts.*</b> If you have not graduated before the application deadline but wish to start the application process, you will receive alternative steps.',
  'praktisch.3step.p3':
    'Your application is screened and you will be notified if the faculty rewards you a <b>Certificate of Acceptance Letter</b> to Devine.',
  'praktisch.3step.notice':
    '*To see the full admission requirements, check out the <iag>the International Application guidelines </iag> here.',
  'praktisch.deadline.title': 'Application Deadlines',
  'praktisch.deadline.p1':
    'Our admissions process works on a rolling basis. Please be aware that this process can take some time; ranging anywhere between 2 to 8 weeks to complete since several departments are involved. So we advise you to apply early in order to secure your place in Devine’s study programme. We are now accepting applications for the academic year 2022-2023. Deadlines for the <b>completed application</b> are:',
  'praktisch.deadline.d1': 'June 1st 2024',
  'praktisch.deadline.p2':
    'for students <visa>who will need to apply for a visa</visa> in order to study in Belgium.',
  'praktisch.deadline.d2': 'July 1st 2024',
  'praktisch.deadline.p3':
    "for EU citizens and other applicants who don't need a visa",
  'praktisch.guide.title': 'Application Essentials',
  'praktisch.guide.p1':
    'Please consult the university’s website to access the latest information on the following topics:',
  'praktisch.guide.li1': 'Detailed description of the admission requirements',
  'praktisch.guide.li2': 'Tuition and study cost',
  'praktisch.guide.li3': 'Laptops at Devine',
  'praktisch.guide.li4': 'Student Grants',
  'praktisch.guide.li5': 'Student Facilities and Housing',
  'praktisch.guide.email':
    'Don’t hesitate to send any further questions to Kim Diependael, International Community Manager: <mail>kim.diependael@howest.be</mail>',
  'praktisch.exchange.title':
    'Interested in an exchange semester at our school?',
  'praktisch.exchange.p1':
    'Check with your international office to see if a student exchange agreement exists between Devine / Howest and your host institution. You can find the needed <info>forms and information on the howest website</info>.',
  'praktisch.exchange.p2':
    'Our entire <curriculum>curriculum</curriculum> is available in English, each semester contains 30 ECTS credits.',
  'praktisch.exchange.p3':
    'For further questions, contact Wouter Verweirder, our International Coordinator: <wouter>wouter.verweirder@howest.be</wouter>',
}
