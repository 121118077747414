import { getCurrentLangKey } from 'ptz-i18n'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { defaultLangKey, langs } from './src/data/languages'
import en from './src/data/messages/en'
import nl from './src/data/messages/nl'
import { Location } from '@reach/router'
const messages = { nl, en }

export default ({ element, pathname }) => {
  let url = 'niks'

  if (!pathname) {
    if (typeof window !== 'undefined' && window.location) {
      url = location.pathname
    }
  } else {
    url = pathname
  }

  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  return (
    <IntlProvider
      key={langKey}
      locale={langKey}
      messages={messages[langKey]}
      defaultLocale={defaultLangKey}
    >
      {element}
    </IntlProvider>
  )
}
