import { BLOCKS } from '@contentful/rich-text-types'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { default as React, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

const Marquee = ({ context, children, contentStyle, outerStyle }) => {
  const outer = useRef()
  const inner = useRef()
  const [speed, setSpeed] = useState(8)
  const { locale } = useIntl()

  const data = useStaticQuery(graphql`
    query ContentfulDynamicContent {
      allContentfulDynamicContent(filter: { name: { eq: "marquee" } }) {
        edges {
          node {
            node_locale
            content {
              raw
            }
            enabled
          }
        }
      }
    }
  `)

  const SPEEDFACTOR = 65

  useEffect(() => {
    if (enabled) {
      const width = inner.current.getBoundingClientRect().width
      setSpeed(width / SPEEDFACTOR)
    }
  }, [inner, enabled])

  // Standaard gewrapped in een P tag, maar willen we niet voor de marquee
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
    },
  }

  //Static query kan geen variabelen bevatten (en het is een component, dus geen pagequery)
  //Dus halen we alles op, en filteren we er de juiste locale uit.
  //Contentfull localisation is van het type 'en-US', wij gebruiken enkel 'en'
  const { content, enabled } = data.allContentfulDynamicContent.edges
    .filter(({ node }) => node.node_locale.includes(locale))
    .map(({ node }) => ({ content: node.content, enabled: node.enabled }))[0]

  if (!enabled) {
    return null
  }

  // create a bunch of copies of the given message
  const messages = []
  const numMessages = 10

  for (let i = 0; i < numMessages; i++) {
    messages.push(
      <span
        key={i}
        ref={inner}
        className={contentStyle}
        style={{ '--marqueeSpeed': `${speed}s` }}
      >
        {renderRichText(content, options)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {'▪'}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
        {String.fromCharCode(160)}
      </span>
    )
  }

  return (
    <div
      data-name="marquee"
      ref={outer}
      className={outerStyle}
      style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      {messages}
    </div>
  )
}

export default Marquee
