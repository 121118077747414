module.exports = {
  'contact.browser.description': '',
  'contact.browser.title': 'Contact - Devine Kask Conservatory',
  'contact.adres.title1': 'Budafabriek{br} Dam 2a {br} 8500 Kortrijk',
  'contact.adres.p1': 'We are located on the first floor of the Buda Factory',
  'contact.contact.title2': 'Contacts',
  'contact.contact.naam1': '',
  'contact.contact.func1': '',
  'contact.contact.cont1': '',
  'contact.contact.naam2': 'Klaus Delanghe',
  'contact.contact.func2': 'Academic Director',
  'contact.contact.cont2':
    'Any other question where you could not find an answer to? Contact Klaus.',
  'contact.contact.naam3': 'Wouter Verweirder',
  'contact.contact.func3': 'International coördinator',
  'contact.contact.cont3':
    'Want to collaborate with Devine on projects? Looking for an (erasmus) partnership? Want to offer international internships? Wouter has all the answers!',
  'contact.contact.naam4': 'Kim Diependael',
  'contact.contact.func4': 'International Community Manager',
  'contact.contact.cont4':
    'Wondering what to expect as an international student at Devine? Curious to learn more about our vibrant international community, or the practicalities of student life in Belgium? Get in contact with Kim.{br}{br}For specific questions about the application process please contact <mail>admissions</mail> directly.',
}
