module.exports = {
  'contact.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'contact.browser.title': 'Contact - Devine Kask Conservatory',
  'contact.adres.title1': 'Budafabriek{br} Dam 2a {br} 8500 Kortrijk',
  'contact.adres.p1':
    'Kom ons gerust bezoeken op het 1e verdiep van de budafabriek.',
  'contact.contact.title2': 'Contactpersonen',
  'contact.contact.naam1': 'Koen De Weggheleire',
  'contact.contact.func1': 'Stage coördinator',
  'contact.contact.cont1':
    'Wil je graag een stage aanbieden aan onze studenten? Neem contact op met Koen.',
  'contact.contact.naam2': 'Klaus Delanghe',
  'contact.contact.func2': 'Opleidingscoördinator',
  'contact.contact.cont2':
    'Nog een vraag over de opleiding waarop je niet meteen een antwoord vond? Contacteer Klaus.',
  'contact.contact.naam3': 'Wouter Verweirder',
  'contact.contact.func3': 'Coördinator internationalisering',
  'contact.contact.cont3':
    'Heb je meer vragen ivm. buitenlandse stages of wil je graag aan Devine studeren maar ben je niet woonachtig in België? Neem dan contact op met Wouter.',
  'contact.contact.naam4': '',
  'contact.contact.func4': '',
  'contact.contact.cont4': '',
}
