import React from 'react'

import HowestHogentLogo from '../assets/img/logos/howest_hogent.inline.svg'
import { container, img, logoWrapper } from './FixedLogo.module.css'

const FixedLogo = () => {
  return (
    <div className={container}>
      <div className={logoWrapper}>
        <HowestHogentLogo className={img} />
      </div>
    </div>
  )
}

export default FixedLogo
