import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

import DefaultFormattedMessage from '../utils/DefaultFormattedMessage'
import {
  content,
  extendedState,
  iconBeh,
  iconEmail,
  iconFb,
  iconGit,
  iconInsta,
  iconLinked,
  iconTwit,
  iconYou,
  invertedSocials,
  socialItem,
  socials,
} from './SocialMedia.module.css'

const SocialMedia = ({
  behance,
  facebook,
  twitter,
  instagram,
  youtube,
  linkedin,
  github,
  email,
  inverted,
  extended,
}) => {
  const listClass = classNames({
    [invertedSocials]: inverted,
    [extendedState]: extended,
    [socials]: !extended,
  })
  const intl = useIntl()

  return (
    <ul className={listClass}>
      {linkedin && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.linkedin' })}
        >
          <a href="https://www.linkedin.com/school/devine-kask-conservatorium-hogent-howest">
            <span className={iconLinked}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.linkedin" />
            </span>
          </a>
        </li>
      )}
      {youtube && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.youtube' })}
        >
          <a href="https://www.youtube.com/channel/UCdOtyJ9ML6PYZRPQsO2KfqA">
            <span className={iconYou}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.youtube" />
            </span>
          </a>
        </li>
      )}
      {behance && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.behance' })}
        >
          <a href="http://be.net/devinekask">
            <span className={iconBeh}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.behance" />
            </span>
          </a>
        </li>
      )}
      {facebook && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.facebook' })}
        >
          <a href="https://www.facebook.com/devinekask">
            <span className={iconFb}></span>{' '}
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.facebook" />
            </span>
          </a>
        </li>
      )}
      {twitter && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.github' })}
        >
          <a href="https://twitter.com/devinekask">
            <span className={iconTwit}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.github" />
            </span>
          </a>
        </li>
      )}
      {instagram && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.instagram' })}
        >
          <a href="https://www.instagram.com/devinekask/">
            <span className={iconInsta}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.instagram" />
            </span>
          </a>
        </li>
      )}
      {github && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.github' })}
        >
          <a href="https://github.com/devinekask">
            <span className={iconGit}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.github" />
            </span>
          </a>
        </li>
      )}
      {email && (
        <li
          className={socialItem}
          title={intl.formatMessage({ id: 'main.social.email' })}
        >
          <a href="mailto:klaus.delanghe@howest.be">
            <span className={iconEmail}></span>
            <span className={extended ? content : 'visually-hidden'}>
              <DefaultFormattedMessage id="main.social.email" />
            </span>
          </a>
        </li>
      )}
    </ul>
  )
}

SocialMedia.defaultProps = {
  behance: true,
  facebook: true,
  twitter: true,
  instagram: true,
  youtube: true,
  github: true,
  linkedin: true,
  email: false,
  extended: false,
}

SocialMedia.propTypes = {
  behance: PropTypes.bool,
  facebook: PropTypes.bool,
  twitter: PropTypes.bool,
  instagram: PropTypes.bool,
  youtube: PropTypes.bool,
  github: PropTypes.bool,
  linkedin: PropTypes.bool,
  inverted: PropTypes.bool,
  extended: PropTypes.bool,
}

export default SocialMedia
