import './src/styles/reset.css'
import './src/styles/style.css'

// forced polyfills for IE11
import 'core-js/es/string/ends-with'
import 'intersection-observer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

import wrapWithProvider from './wrapWithProvider'
export const wrapRootElement = wrapWithProvider

import CustomLayout from './wrapPageElement'
export const wrapPageElement = CustomLayout
