import React from 'react'
import { FormattedMessage } from 'react-intl'
import {keyWord} from './DefaultFormattedMessage.module.css'

const DefaultFormattedMessage = ({ id, values, children }) => {
  return (
    <FormattedMessage
      id={id}
      values={{
        ...values,
        b: content => <strong>{content}</strong>,
        k: content => <strong className={keyWord}>{content}</strong>,
        br: <br />,
      }}
    >
      {children}
    </FormattedMessage>
  )
}

export default DefaultFormattedMessage
