module.exports = {
  'campuslife.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'campuslife.browser.title': 'Campus life - Devine Kask Conservatorium',
  'campuslife.intro.title': 'Budafabriek',
  'campuslife.gebouw.p1':
    'De uitvalsbasis van Devine in Kortrijk,{br} pal in het midden van cultuureiland Buda.',
  'campuslife.eiland.subtitle': 'Buda eiland',
  'campuslife.eiland.p2':
    'Het gonst van de creatieve energie op en rond het Buda-eiland. De meest uiteenlopende organisaties binnen cultuur, onderwijs, zorg en economie zijn er in volle ontwikkeling. Tal van bruisende studenten- en burgerinitiatieven vinden hier een plaats.',
  'campuslife.eiland.p3':
    'Zo is <broelkaai>Broelkaai 6</broelkaai>, de nieuwe <b>creatieve hotspot</b>, juist om de hoek. Als creatieve coworkingspace is deze site dé toegangspoort tot het Buda-eiland en een vitrine voor creativiteit in Kortrijk. Kunstenaars, designers, studenten, creatieve ondernemers, actieve burgers en tal van culturele organisaties gaan er samen aan de slag. En ook jij kan er komen werken, verpozen, eten, drinken, ontdekken en genieten.',
  'campuslife.eiland.p4':
    'Via deze bruisende ontmoetingsplek kan je het hele eiland verder ontdekken, van de <budascoop>Budascoop</budascoop> met haar <b>arthouse cinema</b> tot de Budafabriek. En van de volkstuintjes op Leilekkerland tot de ligweide en skatebowl aan Buda Beach.',
  'campuslife.eiland.subtitle2': 'Buda Kitchen',
  'campuslife.eiland.p5':
    'In <budakitchen>Buda Kitchen</budakitchen> schuif je aan voor pasta, soep, kraakvers bereide dagschotels, home made veggie, originele slaatjes en zoetigheden. Je geniet van een heerlijke lunch aan zeer <b>studentvriendelijke prijzen</b> met een prachtig uitzicht op de Leie.',
}
