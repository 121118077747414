import PropTypes from 'prop-types'

import React from 'react'

import Consent from '../components/Consent'
import FixedLogo from '../components/FixedLogo'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Unibuddy from '../components/Unibuddy'

const Layout = ({ children, location, unibuddy = false }) => {
  return (
    <>
      <a href="#main">Skip links</a>
      <Header />
      {children}
      <Footer />
      <Consent />
      <FixedLogo />
      {unibuddy && <Unibuddy />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
export default Layout
