module.exports = {
  'home.browser.description': '',
  'home.browser.title': 'Devine - Kask Conservatory',
  'home.intro.baseline':
    "<b>Digital Design & Development is a 3-year bachelor's degree fully taught in English and is part of The Royal Academy of Fine Arts (KASK) and the Royal Conservatory.</b>",
  'home.intro.p1':
    'As a Deviner you become someone who is able to reflect, research, design and develop for (future) digital products. Our entire curriculum is based on these 3 core skills.',
  'home.intro.p2':
    "Devine is renowned for its talented students with desirable and highly employable skillset. Once you've graduated, you immediately start as Experience Designer, Front-end Developer, Interaction Designer, Motion Designer, Full Stack Developer, Mobile Developer or simply Deviner.",
  'home.intro.button': 'How to apply?',
  'home.succes.title': 'Alumni in the spotlight',
  'home.succes.p':
    'The final 3 months of Devine is dedicated to an internship. We have an extensive list of internships for students to choose from. You may expect to end up in a challenging creative sector with both national and international agencies. Approximately 85% of the students are offered a permanent contract at the internship. Job security is a fact when you have a Devine diploma. ',
  'home.succesvideo.lieselottitle': "View Lieselot Verheye's story",
  'home.succesvideo.glenntitle': "View Glenn Fellows's story",
  'home.succesvideo.kevintitle': "View Kevin Meyvaert's story",
  'home.succes.morealumni': 'Find our alumni on Linkedin',
  'home.studwerk.title': 'Student life and work',
  'home.studwerk.p':
    'Discover some of the student projects on the official Devine Behance page. Devine students themselfs submit their all time favourite project results. Maybe your work will be on here in the near future?!',
  'home.studwerk.button': 'View student work ',
  'home.curriculum.title': 'Our curriculum',
  'home.des.title': 'Visual Design',
  'home.des.p':
    'Creativity and experience are frequently visited subject matters in the design module. A look at the entire spectrum of digital design reveals topics ranging from visual design, where you acquaint yourself with branding, iconography and typography; all the way to motion graphics where your animations set everything in motion.',
  'home.des.button': 'More on visual design',
  'home.des.img': '',
  'home.dev.title': 'Development',
  'home.dev.p':
    'The beauty of being a Deviner is that you have every skill you need to work on a project from start to finish. You have the perfect balans of skills to design and code your ideas. Building your own visually stunning and functionally exciting digital products, apps and websites from scratch becomes second nature to a Deviner. ',
  'home.dev.button': 'More on development',
  'home.dev.img': '',
  'home.res.title': 'Experience Design',
  'home.res.p':
    "People love digital products that love people. Creating digital products that are valuable to the end user is essential. That's why we check our environment to study the problems and behaviours we perceive around us. We ask ourselves the question: What makes a person tick? What are his or her desires? The strongest researchers fall in love with the problems they find before developing a fitting solution for the end user. Research sets you up for success.   ",
  'home.res.button': 'More on experience design',
  'home.dev.img': '',
}
