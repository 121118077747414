module.exports = {
  'privacy.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'privacy.browser.title': 'Algemene voorwaarden',
  'privacy.cookie.title': 'Cookie-beleid',
  'privacy.s1.title': 'Wat is een cookie?',
  'privacy.s1.p':
    'Wij maken op deze website gebruik van cookies. Een cookie is een eenvoudig klein bestandje dat met pagina’s van deze website wordt meegestuurd en door uw browser op uw harde schrijf van uw computer wordt opgeslagen. De daarin opgeslagen informatie kan bij een volgend bezoek weer naar onze servers teruggestuurd worden.',
  'privacy.s2.title': 'Gebruik van sessiecookies',
  'privacy.s2.p':
    'Met behulp van een sessiecookie kunnen wij zien welke onderdelen van de website u met dit bezoek heeft bekeken. Wij kunnen onze dienst daardoor zoveel mogelijk aanpassen op het surfgedrag van onze bezoekers. Deze cookies worden automatisch verwijderd zodra u uw webbrowser afsluit.',
  'privacy.s3.title': 'Gebruik van permanente cookies',
  'privacy.s3.p':
    'Met behulp van een permanente cookie kunnen wij u herkennen bij een nieuw bezoek op onze website. De website kan daardoor speciaal op uw voorkeuren worden ingesteld. Ook wanneer u toestemming heeft gegeven voor het plaatsen van cookies kunnen wij dit door middel van een cookie onthouden. Hierdoor hoeft u niet steeds uw voorkeuren te herhalen waardoor u dus tijd bespaart en het gebruik van onze website aangenamer kunt maken. Permanente cookies kunt u verwijderen via de instellingen van uw browser.',
  'privacy.s4.title': 'Google Analytics',
  'privacy.s4.p':
    'Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de Analytics-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. Google kan deze informatie aan derden verschaffen indien zij hiertoe wettelijk worden verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google wel/niet toegestaan de verkregen Analytics-informatie te gebruiken voor andere Google-diensten.',
  'privacy.s4.p2':
    'De informatie die Google verzamelt, wordt zoveel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven. De informatie wordt overgebracht naar Google en wordt door hen opgeslagen op servers in de Verenigde Staten. Google stelt zich te houden aan de Privacy Shield Principles en is aangesloten bij het Privacy Shield-programma van het Amerikaanse Ministerie van Handel. Dit houdt in dat er sprake is van een passend beschermingsniveau voor de verwerking van eventuele persoonsgegevens.',
  'privacy.s5.title': 'YouTube',
  'privacy.s5.p':
    "We insluiten video's van ons officiële YouTube-kanaal met behulp van de privacy-verbeterde modus van YouTube. Deze modus kan cookies op uw computer plaatsen zodra u op de YouTube-videospeler klikt, maar YouTube slaat geen persoonlijk identificeerbare cookie-informatie op voor het afspelen van ingebedde video's met behulp van de privacy-verbeterde modus. Ga voor meer informatie naar de informatiepagina voor het insluiten van video's van YouTube.",
  'privacy.s6.title': 'Facebook',
  'privacy.s6.p':
    "Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Facebook, als deel van de 'Facebook Pixel'-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken en gerichte advertenties te tonen op Facebook. Hierbij wordt een gebruikersprofiel opgebouwd tijdens uw bezoek aan deze website. Facebook kan deze informatie aan derden verschaffen indien Facebook hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Facebook verwerken.",
  'privacy.s6.p2':
    'De informatie wordt overgebracht naar Facebook en door wordt door hen voor onbepaalde tijd opgeslagen op servers in de Verenigde Staten.',
  'privacy.s7.title': 'In- en uitschakelen van cookies en verwijdering daarvan',
  'privacy.s7.p':
    'Meer informatie omtrent het in-, uitschakelen en verwijderen van cookies kunt u terugvinden in de instructies en/of met behulp van de Help-functie van uw browser.',
  'privacy.privacy.title': 'Privacy-beleid',
  'privacy.privacy.p':
    'Wij zijn ons er van bewust dat u vertrouwen stelt in ons. Wij zien het dan ook als onze verantwoordelijkheid om uw privacy te beschermen. Op deze pagina laten we u weten welke gegevens we verzamelen als u onze website gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee uw gebruikservaring verbeteren. Zo begrijpt u precies hoe wij werken.',
  'privacy.s9.title': 'Gebruik van onze diensten',
  'privacy.s9.p':
    'Wanneer u zich aanmeldt voor één van onze diensten vragen wij u om persoonsgegevens te verstrekken. Deze gegevens worden gebruikt om de dienst uit te kunnen voeren. De gegevens worden opgeslagen op eigen beveiligde servers. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.',
  'privacy.s10.title': 'Communicatie',
  'privacy.s10.p':
    'Wanneer u e-mail- of andere berichten naar ons verzendt, is het mogelijk dat we die berichten bewaren. Soms vragen wij u naar uw persoonlijke gegevens die voor de desbetreffende situatie relevant zijn. Dit maakt het mogelijk uw vragen te verwerken en uw verzoeken te beantwoorden. De gegevens worden opgeslagen op eigen beveiligde servers. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.',
  'privacy.s11.title': 'Doeleinden',
  'privacy.s11.p':
    'We verzamelen of gebruiken geen informatie voor andere doeleinden dan de doeleinden die worden beschreven in dit privacybeleid tenzij we op voorhand uw toestemming hiervoor hebben verkregen.',
  'privacy.s12.title': 'Derden',
  'privacy.s12.p':
    'De informatie wordt niet met derden gedeeld. In enkele gevallen kan de informatie intern gedeeld worden. Onze werknemers zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.',
  'privacy.s13.title': 'Intellectuele eigendomsrechten',
  'privacy.s13.p':
    'De teksten, afbeeldingen en andere items op deze website worden door het auteursrecht beschermd. Wilt u ze reproduceren of mededelen aan het publiek, dan moet u hiervoor de uitdrukkelijke schriftelijke toestemming verkrijgen, tenzij dat volgens de bepalingen van de wet betreffende het auteursrecht en de naburige rechten niet nodig is. U hebt bijvoorbeeld het recht om informatie van deze website te downloaden en te reproduceren voor privégebruik, alsook het recht om ze gratis te verspreiden en mede te delen in familiekring.',
  'privacy.s14.title': 'Veranderingen',
  'privacy.s14.p':
    'Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen van deze site, kunnen leiden tot wijzigingen in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.',
}
