// extracted by mini-css-extract-plugin
export var acyear = "Footer-module--acyear--c18a5 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--invertedColor--231de";
export var address = "Footer-module--address--cf25c typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--invertedColor--231de";
export var colorNotBlack = "#161615";
export var colorNotWhite = "#f8f8f8";
export var container = "Footer-module--container--b5d4b layout-module--container--d35f3";
export var footer = "Footer-module--footer--afd90";
export var link = "Footer-module--link--8b124 typo-module--invertedColor--231de";
export var links = "Footer-module--links--34636 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var linksTitle = "Footer-module--linksTitle--3ac1a typo-module--heading3--1ef1e typo-module--invertedColor--231de";
export var location = "Footer-module--location--67e88 typo-module--heading3--1ef1e";
export var measure = "60ch";
export var s1 = "calc(.94rem*1.5)";
export var s2 = "calc(calc(.94rem*1.5)*1.5)";
export var s3 = "calc(calc(calc(.94rem*1.5)*1.5)*1.5)";
export var s4 = "calc(calc(calc(calc(.94rem*1.5)*1.5)*1.5)*1.5)";
export var s_1 = "calc(.94rem/1.5)";
export var s_99 = "calc(1rem/16)";
export var social = "Footer-module--social--e1ae8";
export var tokens = "\"../styles/tokens.css\"";
export var voorwaarden = "Footer-module--voorwaarden--f7cd9 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--invertedColor--231de";