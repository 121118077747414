module.exports = {
  'research.browser.description': '',
  'research.browser.title': 'Hi Lab - Devine Kask Conservatory',
  'research.intro.title': 'The lab',
  'research.intro.p1':
    'Hi! Lab was formed in 2019 as an initiative to support and disseminate the research of Devine and further engage the academic department with broader publics, services, and industry.',
  'research.structure.p2':
    'The lab holds two central areas as broader spaces in which to engage and develop novel solutions:',
  'research.structure.subtitle': 'Health and wellbeing technologies',
  'research.structure.p3':
    'Engaging designers with; individuals and/or communities (experiencing challenges to health/wellbeing), healthcare professionals, and/or health service providers, as equal components in the design and development of new solutions.',
  'research.structure.subtitle2':
    'Digital public and community service platforms',
  'research.structure.p4':
    'Concerning broader societal challenges such as, integration, cohesion and identity; providing platforms for under-represented communities; or, empowering citizens as active members in shaping their society through facilitating “bottom-up” change making and civic engagement.',
  'research.example.p5':
    'An example of a research project where the Hi!Lab members actively participate on, is <project>Design For Every One (D4E1)</project>. The project focuses on co-creation and makability in self-supported communities.',
  'research.main.p6':
    'To collaborate with Hi!Lab please contact Giliam Ganzevles',
  'research.main.contact': 'Contact Giliam',
}
