module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-152530435-1","anonymize":true},"googleTagManager":{"trackingId":"G-GMTS3GSL09","defaultDataLayer":{"platform":"gatsby"}},"facebookPixel":{"pixelId":"397955111084270"},"tikTokPixel":{"pixelId":"CACCF33C77U9LQHLQP4G"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devine","short_name":"Devine","start_url":"/","background_color":"white","theme_color":"black","display":"standalone","icon":"src/assets/img/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7f8f74df9371850174d79755a5db9bd9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
