import { Link } from 'gatsby'
import React from 'react'
import { useIntl } from 'react-intl'
import { defaultLangKey, langs } from '../data/languages'
import { getLangs, getUrlForLang } from 'ptz-i18n'

import DefaultFormattedMessage from '../utils/DefaultFormattedMessage'
import {
  active,
  header,
  homelink,
  lang,
  langItem,
  langLink,
  langSelected,
  link,
  list,
  listItem,
  listItemCallout,
  logo,
  marqueeContent,
  marqueeWrapper,
  nav,
} from './Header.module.css'
import LocalLink from './LocalLink'
import Marquee from './Marquee'

const Header = () => {
  const intl = useIntl()
  const langKey = intl.locale
  const url = typeof window !== 'undefined' ? location.pathname : ''

  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/')

  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    (item) => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, '/'),
    })
  )

  return (
    <>
      <Marquee contentStyle={marqueeContent} outerStyle={marqueeWrapper}>
        {langKey === 'nl' ? (
          <a href="http://webreg.howest.be/" tabIndex="-1">
            <DefaultFormattedMessage id="main.marquee.content" />
          </a>
        ) : (
          <DefaultFormattedMessage id="main.marquee.content" />
        )}
      </Marquee>
      <header className={`${header}`}>
        <LocalLink to="/" className={homelink}>
          <h1 className={logo}>
            <span className="visually-hidden">Devine</span>
          </h1>
        </LocalLink>
        {langsMenu.length > 1 ? (
          <ul className={lang}>
            {langsMenu.map((lang) => (
              <li key={lang.langKey} className={langItem}>
                <a
                  className={lang.selected ? langSelected : langLink}
                  selected={lang.selected}
                  href={lang.link}
                >
                  {lang.langKey}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        <nav className={nav}>
          <ul className={list}>
            <li className={listItem}>
              <LocalLink
                to="/curriculum/"
                className={link}
                activeClassName={active}
              >
                Curriculum
              </LocalLink>
            </li>
            <li className={listItem}>
              <LocalLink
                to="/campuslife/"
                className={link}
                activeClassName={active}
              >
                Campus Life
              </LocalLink>
            </li>
            <li className={listItem}>
              <LocalLink to="/hilab/" className={link} activeClassName={active}>
                Hi!&nbsp;Lab
              </LocalLink>
            </li>
            <li className={listItem}>
              <LocalLink
                to="/practical/"
                className={link}
                activeClassName={active}
              >
                <DefaultFormattedMessage id="main.menu.practical" />
              </LocalLink>
            </li>
            <li className={listItem}>
              <LocalLink
                to="/contact/"
                className={link}
                activeClassName={active}
              >
                Contact
              </LocalLink>
            </li>
            {langKey === 'nl' ? (
              <li className={listItemCallout}>
                <LocalLink
                  to="/digitaleinfodag/"
                  className={link}
                  activeClassName={active}
                >
                  Digitale infodag
                </LocalLink>
              </li>
            ) : null}
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header
