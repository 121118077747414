exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campuslife-en-js": () => import("./../../../src/pages/campuslife.en.js" /* webpackChunkName: "component---src-pages-campuslife-en-js" */),
  "component---src-pages-campuslife-nl-js": () => import("./../../../src/pages/campuslife.nl.js" /* webpackChunkName: "component---src-pages-campuslife-nl-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-nl-js": () => import("./../../../src/pages/contact.nl.js" /* webpackChunkName: "component---src-pages-contact-nl-js" */),
  "component---src-pages-curriculum-en-js": () => import("./../../../src/pages/curriculum.en.js" /* webpackChunkName: "component---src-pages-curriculum-en-js" */),
  "component---src-pages-curriculum-nl-js": () => import("./../../../src/pages/curriculum.nl.js" /* webpackChunkName: "component---src-pages-curriculum-nl-js" */),
  "component---src-pages-digitaleinfodag-js": () => import("./../../../src/pages/digitaleinfodag.js" /* webpackChunkName: "component---src-pages-digitaleinfodag-js" */),
  "component---src-pages-hilab-en-js": () => import("./../../../src/pages/hilab.en.js" /* webpackChunkName: "component---src-pages-hilab-en-js" */),
  "component---src-pages-hilab-nl-js": () => import("./../../../src/pages/hilab.nl.js" /* webpackChunkName: "component---src-pages-hilab-nl-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-nl-js": () => import("./../../../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-practical-en-js": () => import("./../../../src/pages/practical.en.js" /* webpackChunkName: "component---src-pages-practical-en-js" */),
  "component---src-pages-practical-nl-js": () => import("./../../../src/pages/practical.nl.js" /* webpackChunkName: "component---src-pages-practical-nl-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-nl-js": () => import("./../../../src/pages/privacy.nl.js" /* webpackChunkName: "component---src-pages-privacy-nl-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tokens-js": () => import("./../../../src/pages/tokens.js" /* webpackChunkName: "component---src-pages-tokens-js" */)
}

