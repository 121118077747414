// extracted by mini-css-extract-plugin
export var colorNotWhite = "#f8f8f8";
export var container = "FixedLogo-module--container--c34f8 layout-module--container--d35f3";
export var content = "FixedLogo-module--content--b0299 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var img = "FixedLogo-module--img--19449";
export var logoWrapper = "FixedLogo-module--logoWrapper--a35ba";
export var s0 = ".94rem";
export var s1 = "calc(.94rem*1.5)";
export var s2 = "calc(calc(.94rem*1.5)*1.5)";
export var s3 = "calc(calc(calc(.94rem*1.5)*1.5)*1.5)";
export var s_1 = "calc(.94rem/1.5)";
export var s_2 = "calc(calc(.94rem/1.5)/1.5)";
export var s_99 = "calc(1rem/16)";
export var tokens = "\"../styles/tokens.css\"";