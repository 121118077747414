module.exports = {
  'curriculum.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'curriculum.browser.title': 'Curriculum - Devine Kask Conservatorium',
  'curriculum.main.jobroles': 'Jobroles',
  'curriculum.intro.title': 'Waarom 3 pijlers?',
  'curriculum.intro.p':
    'Als Deviner gebruik je <b>design-, development- en ux skills</b> om online ervaringen vandaag én in de toekomst vorm te geven. Elke skill is een pijler in ons curriculum waarbinnen we jouw expertise opbouwen. In je laatste jaar Devine kies je één van deze pijlers om uit te diepen en wordt je een echte expert.',
  'curriculum.intro.button1': 'Experience design',
  'curriculum.intro.button2': 'Visual design',
  'curriculum.intro.button3': 'Development',
  'curriculum.samen.title': 'Hoe brengen we dit samen?',
  'curriculum.studpunt.title': 'Het opleidingsprogramma',
  'curriculum.studpunt.intro':
    'Ons opleidingsprogramma evolueert mee met de actuele trends. Het onderstaande programma is altijd onder voorbehoud van wijzigingen',
  'curriculum.studpunt.y1': 'Eerste jaar',
  'curriculum.studpunt.y2': 'Tweede jaar',
  'curriculum.studpunt.y3': 'Derde jaar',
  'curriculum.samen.p':
    'Integratie is de pijler waar al jouw kennis aan elkaar geknoopt wordt. Wat je leerde in de pijlers visual design, development en experience design blijft op deze manier niet beperkt tot een éénmalige of theoretische ontmoeting. Bovendien pas je je kennis toe op jouw eigen creaties waardoor je nog meer inzicht krijgt in de leerstof. ',
  'curriculum.samen.button': 'Integration',
  'curriculum.design.title': 'Visual design',
  'curriculum.design.subtitle': 'Zien, horen en creëren.',
  'curriculum.design.p1':
    'Wie ontwerpt, leert <b>anders naar de wereld kijken</b>. Het omvormen van deze wereld tot jouw eigen ontwerp is de start van je designmodules. Enerzijds word je een expert in de programma’s die in het vakgebied worden gebruikt, aan de andere kant ontwikkel je je creatief denkvermogen. Je gaat aan de slag met brainstormtechnieken om tot ideeën te komen. Je laat je inspireren om vervolgens te experimenteren met typografie, beeld en compositie.{br}Maar we kijken ook verder dan het scherm. Je bezoekt tentoonstellingen, verdiept je in de kunstgeschiedenis en traint je tekenskills. Zo leer je hoe je verhalen visueel kan vertalen met storytellingtechnieken en zet je jouw ontwerpen nog meer kracht bij.',
  'curriculum.design.subtitle2': 'Ontwerpen voor digital products.',
  'curriculum.design.p2':
    'In het 2de jaar Devine nemen we de wensen van je klant en doelgroep mee in het designproces. Van een uitgeschreven concept, maak jij een wervend geheel. Je weet nu welke informatie, op welke manier en op welk moment aan je doelgroep gecommuniceerd moet worden.{br}Parallel met development leer je ontwerpen voor de vele schermgroottes die je doorheen de dag bekijkt. Zo leer je ontwerpen voor ‘touch’ en creëer je over de verschillende toestellen een consistente ervaring. Digital design krijgt steeds meer nieuwe betekenissen. We verdiepen ons in <b>design thinking</b>, designsystems en de verschillende platformen waarvoor we ontwerpen. We maken niet langer websites maar zetten in op toekomstgerichte digitale producten.',
  'curriculum.design.subtitle3': 'Motion graphics in 3D. ',
  'curriculum.design.p3':
    'Je zet je eerste stappen in de wereld van Motion Graphics. Je gaat aan de slag met After Effects en maakt onder begeleiding je eerste animaties. Je staat stil bij het proces van <b>motion graphics</b>: idea, planning, storyboards, stylesframes,.. Je tilt je animaties naar een hoger niveau door aandacht te besteden aan stylization, transities/match cuts en secundaire animaties.',
  'curriculum.design.p4':
    'In de design expertmodule verdiep je je volledig in de visuele digitale wereld. Je zorgt voor delight in de interface gecombineerd met krachtige beeldtaal. Je krijgt een introductie Blender. We zorgen ervoor dat je je weg vindt in de complexe wereld van 3D animatie en bouwen stelselmatig aan een project die de basisprincipes van 3D modeling, texturing, lighting en rendering omvat. Je krijgt de kans om <b>talks, meet-ups en conferenties</b> bij te wonen die relateren aan jouw expertise. Je bepaalt zelf waar je verder op wil specialiseren en werkt een eigen design exploratieproject uit. Je coach begeleidt je doorheen dit hele project en je sluit de designpeiler af door je werk te presenteren aan een juryleden uit de sector.',
  'curriculum.dev.title': 'Development',
  'curriculum.dev.subtitle1': 'Bouwen in code.',
  'curriculum.dev.p1':
    'In development train je jezelf in probleemoplossend denken aan de hand van verschillende programmeertalen. We passen in het eerste jaar het principe van pair-programming toe. Zo werk je zij aan zij met je klasgenoten en help je elkaar in de juiste richting. Je zet de structuur van je site op in HTML, geeft hem vorm in CSS om tot slot interactiviteit toe te voegen via JavaScript.{br}Vervolgens concentreren we ons op het bouwen van web applicaties waar een database aan gekoppeld is. Zo kan je na één jaar Devine reeds je eigen webshop bouwen.',
  'curriculum.dev.subtitle2': 'Programmeren in team.',
  'curriculum.dev.p2':
    'In het tweede jaar houden we rekening met de vele toestellen waarop we een site of applicatie bekijken. Door het ‘responsive’ maken van je website zorgen we ervoor dat jouw creatie er op elk toestel perfect uitziet. Je projecten worden ook stilaan groter. Je leert tools en technieken inzetten zodat werken in een team nog vlotter verloopt.{br}Vanaf nu <b>bouw je aan de interfaces van morgen</b>. We verkennen samen het landschap om performante en gebruiksvriendelijke interfaces te maken voor webapplicaties en laten deze communiceren met een dataservice. Je leert hoe je snel prototypes kan maken om deze vervolgens uit te testen bij jouw gebruikers.',
  'curriculum.dev.subtitle3': 'Creatief met code. ',
  'curriculum.dev.p3':
    'In het laatste jaar ben je helemaal klaar voor een <b>flinke dosis experiment</b>. Je gaat aan de slag met Arduinos, WebGL, Machine Learning en real time communicatie. Voor het eerst bouwen we ook fysiek aan onze digitale ideeën. Zo maken we installaties die bijvoorbeeld een plaats kunnen krijgen op festivals of musea.  Je gebruikt je programmeerkennis om een echte beleving te creëren. Van een game tot bewegende lichtprojectie op gebouwen.{br}De experts development nemen deel aan hackatons en spitsen zich verder toe op hun persoonlijk project. Ook hier krijgen de experts de kans zich helemaal te verdiepen in de materie naar keuze, van Artificial Intelligence tot Full Stack Web Development.',
  'curriculum.research.title': 'Experience design',
  'curriculum.research.subtitle1': 'Doelgericht content creëren. ',
  'curriculum.research.p1':
    'In de experience design modules leer je kritisch kijken naar de gebruiker en zijn omgeving. In actieve workshops bestudeer je <b>de noden en het gedrag van de gebruiker</b>. Je wordt een ux designer en communiceert je bevindingen via een paper waarin een user journey map, content matrix, flowcharts en wireframes verwerkt zijn. Zo maak je een gedetailleerd skelet op van je digitaal eindproduct. ',
  'curriculum.research.subtitle2': 'Het verzamelen van data. ',
  'curriculum.research.p2':
    'In het 2e jaar spits je jezelf toe op <b>je eigen onderszoeksvraag</b>. Je richt je kritische blik op de maatschappij en zoekt naar opportuniteiten voor digitale innovatie. Je gaat zelfstandig op pad om data te vergaren. Zie jij patronen opduiken in je dataset? Kan je deze groeperen en verwerken naar een bruikbaar geheel? Correct leren omgaan met gevoelige informatie is hierbij een vereiste. Je leert in de lessen over de <b>ethische implicaties van digitale disruptie</b>. Je kennis over flowcharts en wireframes wordt verder uitgebreid en krijgt vorm door interactieve prototypes.',
  'curriculum.research.subtitle3': 'Een blik op de toekomst. ',
  'curriculum.research.p3':
    'In je laatste jaar Devine krijg je lezingen over de belangrijke thema’s in het domein van mens-computerinteractie. Zo verdiepen we ons o.a. in de oorsprong ervan en de gangbare internationale onderzoeksmethodieken.{br}Ben je helemaal verslingerd aan UX en Experience Design? Dan kan je in de laatste jaar Experience Design inzetten als je expert module. Kies voor een passion project in Experience Design waarbij jouw eigen exploratie en onderzoek centraal staat onder begeleiding van een persoonlijke mentor.',
  'curriculum.integr.title': 'Integration',
  'curriculum.integr.p1':
    'In Devine geloven we dat onze kennis en skills niet enkel de studenten ten goede komt, maar de hele maatschappij. De commerciële én publieke sector, sociale en culturele instellingen,… moeten de creativiteit en innovativiteit van onze studenten kunnen ervaren. In de integratie module werken studenten bijgevolg samen met een opdrachtgever aan echte cases en vraagstukken. In deze module combineer je je opgedane skills en kennis om digitale producten, services of systemen te ontwikkelen die beantwoorden aan de altijd-veranderende verwachtingen van je doelgroep. Je denkt na hoe je op een objectieve manier kan informeren, awareness kan creëren of misschien wel gedrag kan laten veranderen. Je denkt op een holistische manier na over de meerwaarde en duurzaamheid van je digitale oplossing voor de ‘wicked problems’ uit onze maatschappij.',
  'curriculum.integr.p2':
    'De integratiemodule bundelt de skills die je in Design, Development en UX hebt aangeleerd. De reële challenges ga je aan op het einde van elke semester.Je werkt in de stimulerende creatieve omgeving van onze campus aan het project, individueel of in groep. De klassieke lesvormen maken plaats voor gepersonaliseerde feedback. Je zit samen met je docenten en komt regelmatig je progressie aftoetsen.',
  'curriculum.integr.subtitle': 'Hoe verloopt deze module?',
  'curriculum.integr.p3':
    'Terwijl je jouw ideeën in het eerste jaar nog aftoetst met je docenten, verkoop je deze in je laatste jaar aan reële klanten. Integratie geeft je de kans je eigen planning op te maken, te experimenteren en je project in eigen handen te nemen. In de theoriemomenten maken we plaats voor relevante topics met betrekking tot je project. Zo laten we onder andere trendwatchers aan het woord, krijg je workshops omtrent teamwork en leer je hoe je de perfecte elevator pitch opstelt. De bachelorproef is het allerlaatste integratieproject die je uitvoert vooraleer je op stage vertrekt en afstudeert. Je werkt op een concrete onderzoekscase uit het werkveld. Je krijgt de tijd om je project te laten schitteren tijdens een presentatie voor een open jury uit het werkveld. {br}Hét sluitstuk van je opleiding en de spreekwoordelijke kers op de Devine taart is natuurlijk je stage waarin je 3 maand werkt in een bedrijf naar jouw keuze.',
  'curriculum.integr.buttoninternship': 'Bekijk de stagefilmpjes op Youtube',
  'curriculum.integr.subtitle2': 'Internationale uitwisseling',
  'curriculum.integr.p4':
    'Voor Integration in het 2e jaar bundelen we vier weken lang de krachten met Hogeschool Rotterdam. We reizen hiervoor over de landsgrenzen heen en logeren een week in Rotterdam. Samen met Rotterdamse studenten werk je in kleine interdisciplinaire teams voor unieke klanten als Opera Ballet Vlaanderen, Museum voor Schone Kunsten Gent, Museum Boijmans Van Beuningen,...{br}Je bundelt de skills van je team en gaat samen op zoek naar een innovatieve oplossing op maat van de klant. Wanneer de Rotterdamse studenten op hun beurt op onze campus vertoeven, werken jullie samen aan een prototype. Deze integratie weken vormen de perfecte voedingsbodem voor de bachelorproef en stomen je langzaam klaar voor het werkveld.',
  'curriculum.integr.buttonrotterdam': 'rotterdam aftermovie',
}
