import home from './home'
import practical from './practical'
import main from './main'
import curriculum from './curriculum'
import campus from './campus'
import research from './research'
import contact from './contact'
import privacy from './privacy'
import digiinfo from './digitaleinfodag'

export default {
  ...home,
  ...practical,
  ...curriculum,
  ...main,
  ...campus,
  ...research,
  ...contact,
  ...privacy,
  ...digiinfo,
}
