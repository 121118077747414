module.exports = {
  'praktisch.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'praktisch.browser.title': 'Praktisch - Devine Kask Conservatorium',
  'praktisch.nav.title1': 'Hoe inschrijven?',
  'praktisch.nav.title2': 'Wat na je studies?',
  'praktisch.nav.title3': 'Al een diploma?',
  'praktisch.afterlife.title': 'Na je studie',
  'praktisch.afterlife.subtitle': 'Start van je carrière',
  'praktisch.afterlife.p1':
    'Je sluit je studie Devine af met een stage. Ongeveer 85% van de studenten krijgt op de stageplaats een vast contract aangeboden. Werkzekerheid is een feit wanneer je een diploma Devine op zak hebt. Je komt terecht in een uitdagende creatieve sector met zowel kleine als grote agencies. Over heel Vlaanderen en daarbuiten zijn alumni aan de slag.',
  'praktisch.afterlife.data1': 'Buitenlandse stages',
  'praktisch.afterlife.data2': 'Jobaanbiedingen tijdens stage',
  'praktisch.afterlife.data3': 'is 6 maand na afstuderen actief in de sector',
  'praktisch.afterlife.button': 'Bekijk de stagefilmpjes',
  'praktisch.afterlife.subtitle2': 'Mogelijke stageplaatsen ',
  'praktisch.subtitle.verder': 'Verder studeren?',
  'praktisch.afterlife.p2':
    'Heb je ambitie om na Devine verder te studeren? Dan zijn er verschillende mogelijkheden afhankelijk van jouw interesses. Verder specialiseren in Experience Design kan door het volgen van een <postgraduaat>postgraduaat</postgraduaat>. Voor development kan je dan weer terecht bij de opleiding <mct>Multimedia and Communication Technology</mct> of door het volgen van een postgraduaat <blockchain>Blockchain Developer</blockchain> van <ti>Toegepaste Informatica</ti>. {br}Een Deviner die zijn of haar animatieskills verder wil uitbouwen kan binnenkort schakelen naar de opleiding <kaskanimatie>Animatie en Film</kaskanimatie> binnen het KASK. Ambieer je verder te ontwikkelen op vlak van 3D en VR? Dan zit je goed bij de opleiding <dae>Digital Arts & Entertainment</dae>. {br}Ook binnen de opleiding zijn er doorgroeimogelijkheden. Zo kan je mee werken in ons researchlab aan boeiende onderzoeken of  een Masterclass meevolgen in het Industrial Design Center (IDC howest) waar je aan de slag gaat in de R&D afdeling van grote multinationals zoals bvb. Barco. ',
  'praktisch.iot.title': 'Al een diploma?',
  'praktisch.iot.p':
    'Heb je al een diploma Hoger onderwijs op zak? Wil je werk combineren met studeren? Wil je studies combineren? Dan kan je terecht bij Mevr. Griet Eechout voor eventuele vrijstellingen of persoonlijke trajecten in de opleiding. ',
  'praktisch.iot.button': 'Contacteer Griet',
}
