import { Link } from 'gatsby'
import React from 'react'
import { useIntl } from 'react-intl'

const LocalLink = (props) => {
  const { locale, defaultLocale } = useIntl()
  return (
    <Link
      {...props}
      to={
        locale != defaultLocale
          ? props.to.replace('/', `/${locale}/`)
          : props.to
      }
    >
      {props.children}
    </Link>
  )
}

export default LocalLink
