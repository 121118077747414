module.exports = {
  'digiinfo.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'digiinfo.browser.title': 'Digitale infodag - Devine Kask Conservatorium',
  'digiinfo.intro.titel': 'Is jouw toekomst Devine?',
  'digiinfo.intro.p1':
    'Ben jij gebeten door design en code? Een creatieve opleiding met jobperspectief? Laat je e-mailadres achter en blijf op de hoogte van onze infodagen en bootcamps:',
  'digiinfo.intro.formbutton': 'Ik wil op de hoogte blijven',
  'digiinfo.intro.formsubmitted': 'Bedankt, we houden je op de hoogte.',
  'digiinfo.intro.bezoek': 'Bezoek ons op afspraak',
  'digiinfo.intro.p2':
    'Schrijf de dagen van de bootcamps al zeker in je agenda:',
  'digiinfo.curri.titel': 'De 3 pijlers van Devine',
  'digiinfo.curri.p1':
    'Als Deviner gebruik je design-, development- en ux skills om online ervaringen vandaag én in de toekomst vorm te geven. Elke skill is een pijler in ons curriculum waarbinnen we jouw expertise opbouwen. In je laatste jaar Devine kies je één van deze pijlers om uit te diepen en wordt je een echte expert.',
  'digiinfo.curri.p2':
    'Onze docenten vertellen kort wat je kan verwachten in elke pijler. We monteerden alvast veel studentenwerk en stukjes uit het campusleven ertussen om jou een sneak peek te geven in je toekomstige opleiding.',
  'digiinfo.curri.b1': 'meer over visual design',
  'digiinfo.curri.b2': 'meer over development',
  'digiinfo.curri.b3': 'meer over experience design',
  'digiinfo.curri.b4': 'meer over integration',
  'digiinfo.studimat.titel': 'Studiemateriaal',
  'digiinfo.studimat.button': 'Bekijk studiemateriaal',
  'digiinfo.studimat.p1':
    'Om je een idee te geven hoe de cursussen, lessen, en werk van studenten eruit zien, verzamelden we studiemateriaal die je kan inkijken. ',
  'digiinfo.tour.titel': 'Ontdek de volledige campus',
  'digiinfo.tour.p1':
    'De Budafabriek is de uitvalsbasis van Devine in Kortrijk, pal in het midden van cultuureiland Buda.',
  'digiinfo.tour.p2':
    'Vlieg alvast mee door onze campus en vergeet niet te zwaaien vanop het dakterras.',
  'digiinfo.tour.video': 'Devine Virtual Campus Tour',
  'digiinfo.alumni.titel': 'Onze alumni komen altijd en overal terecht',
  'digiinfo.form.titel': 'Hoe inschrijven?',
  'digiinfo.form.p1':
    'Wil je graag Devine studeren? Dan dien je tegen je definitieve inschrijving een Belgisch diploma secundair onderwijs op zak te hebben. Je kan je vanaf zaterdag 1 maart inschrijven via de <webregistratie>webregistratie</webregistratie>.',
  'digiinfo.form.subtitel1': 'Lijkt Devine iets voor jou?',
  'digiinfo.form.p2':
    'Twijfel je nog? Kom meedoen met een bootcamp waar je na 1 halve dag  je talent ontdekt en waarbij we je helpen met de juiste keuze voor je toekomst.',
  'digiinfo.form.subtitel2': 'Deze bootcamps gaan door op:',
  'digiinfo.form.titel2': 'Op de hoogte blijven?',
  'digiinfo.form.p4': 'Laat jouw gegevens hier achter.',
  'digiinfo.form.noboot':
    'Momenteel zijn er geen bootcamp momenten gepland, maar laat hier jouw gegevens achter en blijf zo op de hoogte.',
  'digiinfo.faq.titel': 'FAQ',
  'digiinfo.faq.q1': 'Is er voorkennis vereist?',
  'digiinfo.faq.a1':
    'Alle voorkennis die je hebt is mooi meegenomen. Je weet beter waar je aan begint en wat misschien jouw sterkte is. Voorkennis is echter geen vereiste. We starten in elke module op niveau 0. Samen bouwen we in een stevig tempo aan jouw skills.',
  'digiinfo.faq.q2': 'In welke sector kom je terecht?',
  'digiinfo.faq.a2':
    "Je komt terecht in de wereld van digitale communicatie en reclamebureau's, content marketing bedrijven, event builders, creatieve studio's, motion agencies,... Afhankelijk van jouw gekozen expertise in het laatste jaar vervul je een andere rol binnen het bedrijf.",
  'digiinfo.faq.q3': "Wat houdt 'Motion' precies in? Gaan we leren filmen?",
  'digiinfo.faq.a3':
    'We leren je niet filmen. Jij verwerkt het videomateriaal dat je aangeleverd krijgt en monteert dit tot een vlot geheel. We leren jou animeren in 2D en in de expert Design ga je aan de slag met 3D animaties. Je werkt aan visual identities, zoals bijvoorbeeld logo animaties voor Nike. Je ontwikkelt uiteindelijk een eigen illustratie- en animatiestijl zoals bijvoorbeeld onze alumnus Soren Selleslagh. Ontdek zijn portfolio op <ss>sorenselleslagh.com</ss>',
  'digiinfo.faq.q4': 'Wat kan ik doen om me voor te bereiden op design?',
  'digiinfo.faq.a4':
    'We verwachten van jou een open blik op de wereld rondom je. Ga naar musea, theater, muziek... Wil je jouw illustratie-skills alvast opkrikken kan je op <illu>deze site</illu> alvast de basistechnieken ontdekken. Of zoek voor jezelf een 30 day drawing challenge online!',
  'digiinfo.faq.q5': 'Hoe groot zijn de klasgroepen?',
  'digiinfo.faq.a5':
    'In Devine kiezen we voor kleine groepen van gemiddeld 25 studenten. Deze klasgroepen combineren we naar gelang het soort les. Voor theorie zit iedereen samen, voor sommige modules met twee klasgroepen. Dit geeft veel flexibiliteit en maakt de lessen persoonlijk.',
  'digiinfo.faq.q6': 'Waarom doe je onderzoek?',
  'digiinfo.faq.a6':
    'Onderzoek naar het ontwerp- en ontwikkelingsproces is de sleutel om te weten waarom je iets maakt, en of het de juiste oplossing is. Je doet onderzoek om digitale producten te maken die een meerwaarde zijn voor mensen en hun leven, en om bestaande producten en diensten te verbeteren.',
  'digiinfo.faq.q7': 'Moet je veel wiskunde gezien hebben in het secundair?',
  'digiinfo.faq.a7':
    'Pure algebra en dergelijk komt er niet aan te pas. We doen wel een beroep op jouw analytisch vermogen. Programmeren is vaak een kwestie van logisch denken, abstraheren, connecties maken… hoe meer wiskunde je hebt gehad, hoe meer je dit -onrechtstreeks- traint. Studenten met minder dan 3 uur wiskunde in hun middelbare studies hebben het moeilijker in onze development lessen.',
  'digiinfo.faq.q8': 'Hoeveel bedraagt de studiekost?',
  'digiinfo.faq.a8':
    'Een overzicht van de studiekost kan je <how>terugvinden op de pagina van Howest.</how>',
  'digiinfo.faq.q9': 'Kan je makkelijk een kot vinden in Kortrijk?',
  'digiinfo.faq.a9':
    'Het aanbod van koten is in Kortrijk is voldoende ruim. Je kan alvast eens rondkijken op <kot>kotwest.be</kot> om een idee te krijgen van de prijzen en locaties. Er zijn natuurlijk nog veel koten te vinden buiten dit aanbod.',
  'digiinfo.faq.q10': 'Kan ik zelf een stage kiezen?',
  'digiinfo.faq.a10':
    'In het laatste jaar kies je zelf je stageplaats. Eén derde van onze studenten trekt naar het buitenland. We verzamelen elk jaar de stage-aanvragen van bedrijven op ons eigen platform waaruit je eveneens kan kiezen. In deze lijst staan meer dan honderd top digital agencies van Vlaanderen.',
  'digiinfo.faq.q11': 'Kan ik specialiseren?',
  'digiinfo.faq.a11':
    'In je laatste jaar kies je jouw specialisatie: Experience Design, Visual Design of Development. Binnen jouw specialisatie krijg je extra skills aangereikt en de kans om een persoonlijk passion project uit te werken.',
  'digiinfo.faq.q12': 'Welke laptop heb ik nodig?',
  'digiinfo.faq.a12':
    'Als je kiest om Devine te studeren, dien je een <mac>MacBook van Apple</mac> aan te kopen. Je kan deze aankopen met een studentenkorting.{br}Omdat studenten soms twijfelen over de opleiding en de kost van een Macbook, staan we het toe om met een ander toestel te werken (bvb een windows laptop) <b>tot het einde van het eerste semester in het eerste jaar.</b> Vanaf januari is het voor alle studenten verplicht om met een Apple laptop te werken.',
  'digiinfo.vraag.titel': 'Jouw vraag toch nog niet beantwoord?',
  'digiinfo.vraag.p1':
    'Geen zorgen, contacteer ons via een van onze kanalen of stuur een instagram bericht. Wij komen direct bij je terug.',
  'digiinfo.nav.b1': 'Blijf op de hoogte',
  'digiinfo.nav.b2': 'Bezoek ons op afspraak',
  'digiinfo.nav.b3': 'Schrijf je in',
  'digiinfo.nav.b4': 'Contacteer ons',
  'digiinfo.videoscroll.title': 'Welkom op de campus',
  'digiinfo.videoscroll.h1': 'Welkom in de Budafabriek',
  'digiinfo.videoscroll.p1':
    'Wij geloven dat creatieve makers op een creatieve plek horen samen te komen. Deze oude fabriek (met dakterras) combineert werkplekken met chill-spots.',
  'digiinfo.videoscroll.h2': 'Get together',
  'digiinfo.videoscroll.p2':
    'Een geolied team zet vaak de beste projecten neer. Zet je laptops naast elkaar en help elkaar vooruit!',
  'digiinfo.videoscroll.h3': 'Sit down and relax',
  'digiinfo.videoscroll.p3':
    'Breng de zotste ideeën naar boven met een brainstormsessie.',
  'digiinfo.videoscroll.h4': 'Atelier',
  'digiinfo.videoscroll.p4':
    'In het atelier ga je hands-on aan de slag met allerlei challenges. Richt de ruimte in zoals je zelf comfortabel vind en creëer je eigen hub.',
  'digiinfo.videoscroll.h5': 'Your future looks Devine',
  'digiinfo.videoscroll.p5':
    'Scroll verder om er achter te komen hoe jij de toekomst vorm kan geven!',
  'digiinfo.preloader.p1': 'building the digital campus',
  'digiinfo.preloader.p2': 'placing plants',
  'digiinfo.preloader.p3': 'restarting the wifi network',
  'digiinfo.preloader.p4': 'fueling the drone',
}
