import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const Unibuddy = () => {

  const [cookies] = useCookies(['accepted'])
  const consent = cookies['accepted']

  useEffect(() => {
    if (consent) {
      window.unibuddyBubbleSettings = {
        colour: '44c8f5',
        universitySlug: 'howest',
        prompt: 'Find everything you need?',
        widgetUrl: `https://unibuddy.co/embed/howest/course/5f7750bba8a82432fbc247fa`,
        ubLang: 'en-GB',
        ubCookieConsent: "necessary",
      }

      const script = document.createElement("script");
      script.src = "https://traffic-drivers.unibuddy.co/chat-bubble/embed.js";
      script.async = true;
      script.defer = true
      document.body.appendChild(script);

      return () => {
        const bubble = document.querySelector("#unibuddy-chat-bubble-iframe");
        if (bubble) {
          document.body.removeChild(bubble);
        }
        if (script) {
          document.body.removeChild(script);
        }
      }
    }
  }, [consent])

  return null;
}

export default Unibuddy;
