module.exports = {
  'research.browser.description':
    'Nu overal de algemene beschrijving, maar dit moet dus nog gespecifieerd worden voor deze pagina',
  'research.browser.title': 'Hi Lab - Devine Kask Conservatorium',
  'research.intro.title': 'Hi! lab',
  'research.intro.p1':
    'Hi! Lab is gevormd in 2019 als een initiatief om de disseminatie van het onderzoek door Devine te ondersteunen en het engagement naar de maatschappelijke dienstverlening te versterken. ',
  'research.structure.p2':
    'Het lab heeft 2 domeinen als brede afbakening waarbinnen gezocht wordt naar nieuwe uitdagingen en oplossingen.',
  'research.structure.subtitle': 'Gezondheid en Welzijn',
  'research.structure.p3':
    'Interactie researchers connecteren met individuals en/of gemeenschappen, gezondheidsprofessionals en aanbieders van uit de gezondsheidssector. Als gelijkwaardige partners zetten we stappen in de zoektocht naar nieuwe digitale oplossingen of innovaties.',
  'research.structure.subtitle2': 'Publieke digitale service platformen',
  'research.structure.p4':
    "Een focus op de brede maatschappelijke uitdagingen zoals: integratie, samenhorigheid en identiteit. We doen onderzoek naar het aanbieden van platformen voor ondervertegenwoordigde gemeenschappen. We versterken inwoners als actieve deelnemers in het vormgeven van hun eigen context door een 'bottom-up' aanpak te faciliteren.",
  'research.example.p5':
    'Een voorbeeld van een onderzoeksproject waarbij de onderzoeksgroep actief is bij betrokken, is Design For Every One (D4E1). Het project <project>D4E1</project> legt de focus op co-creatie en makability in self supported communities.',
  'research.main.p6':
    'Interesse om samen te werken met Hi!Lab? contacteer de onderzoekscoördinator: Giliam Ganzevles',
  'research.main.contact': 'Contacteer Giliam',
}
