// extracted by mini-css-extract-plugin
export var active = "Header-module--active--2318d";
export var colorNotBlack = "#161615";
export var colorNotWhite = "#f8f8f8";
export var fontSizeM = "1.13rem";
export var header = "Header-module--header--923a3 layout-module--container--d35f3";
export var homelink = "Header-module--homelink--1a27d";
export var lang = "Header-module--lang--01b9e";
export var langItem = "Header-module--langItem--1d417";
export var langLink = "Header-module--langLink--30e83 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--textBodyLarge--b9e1f typo-module--textBodyLargeBase--cb610";
export var langSelected = "Header-module--langSelected--13afa Header-module--langLink--30e83 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c Header-module--langLink--30e83 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--textBodyLarge--b9e1f typo-module--textBodyLargeBase--cb610";
export var link = "Header-module--link--3741f typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c typo-module--textBodyLarge--b9e1f typo-module--textBodyLargeBase--cb610";
export var list = "Header-module--list--8c2ff";
export var listItem = "Header-module--listItem--ef4c7";
export var listItemCallout = "Header-module--listItemCallout--2c376 Header-module--listItem--ef4c7";
export var logo = "Header-module--logo--9b73c";
export var marquee = "Header-module--marquee--e6c56";
export var marqueeContent = "Header-module--marqueeContent--49755 typo-module--heading3--1ef1e typo-module--invertedColor--231de";
export var marqueeWrapper = "Header-module--marqueeWrapper--a2ae5";
export var nav = "Header-module--nav--f677b";
export var s0 = ".94rem";
export var s1 = "calc(.94rem*1.5)";
export var s2 = "calc(calc(.94rem*1.5)*1.5)";
export var s3 = "calc(calc(calc(.94rem*1.5)*1.5)*1.5)";
export var s_1 = "calc(.94rem/1.5)";
export var s_2 = "calc(calc(.94rem/1.5)/1.5)";
export var s_99 = "calc(1rem/16)";
export var tokens = "\"../styles/tokens.css\"";