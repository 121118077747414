// extracted by mini-css-extract-plugin
export var button = "Consent-module--button--0d132 components-module--linkButton--44c32 components-module--baseButton--66ac8 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var colorNotWhite = "#f8f8f8";
export var container = "Consent-module--container--dcb65";
export var content = "Consent-module--content--e8af0 typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var more = "Consent-module--more--be25e typo-module--textBody--ad534 typo-module--baseBody--d94a1 typo-module--fontfeatures--7805c";
export var s0 = ".94rem";
export var s1 = "calc(.94rem*1.5)";
export var s2 = "calc(calc(.94rem*1.5)*1.5)";
export var s3 = "calc(calc(calc(.94rem*1.5)*1.5)*1.5)";
export var s_1 = "calc(.94rem/1.5)";
export var s_2 = "calc(calc(.94rem/1.5)/1.5)";
export var s_99 = "calc(1rem/16)";
export var tokens = "\"../styles/tokens.css\"";