module.exports = {
  'privacy.browser.description': '',
  'privacy.browser.title': 'General terms & Conditions',
  'privacy.cookie.title': 'Cookie Policy',
  'privacy.s1.title': 'What is a cookie?',
  'privacy.s1.p':
    'This website uses cookies. A cookie is a simple, small file that is sent together with pages from this website, which your browser saves on the hard drive of your computer. The information saved in a cookie can be sent back to our servers when you visit the website again.',
  'privacy.s2.title': 'Use of session cookies',
  'privacy.s2.p':
    'We use session cookies to be able to see which parts of the website you viewed during this visit. This allows us to adapt our services to the browsing behaviour of our visitors as much as possible. These cookies are automatically deleted as soon as you close your web browser.',
  'privacy.s3.title': 'Use of permanent cookies',
  'privacy.s3.p':
    'We use permanent cookies to be able to recognise you when you come back to our website. This allows you to set up the website according to your preferences. We can even remember that you agreed to allow cookies to be saved using a cookie. This way, you do not have to repeat your preferences at each visit, which saves you time and makes using our website more enjoyable. Permanent cookies can be deleted via your browser settings.',
  'privacy.s4.title': 'Google Analytics',
  'privacy.s4.p':
    'The American company, Google, installs a cookie via our website as a part of its ‘Analytics’ service. We use this service to receive updated reports on how visitors use our website. Google can provide this information to third parties if Google is legally required to do so, or if these third parties process the information for Google. We do not have any influence on this. We have permitted/not permitted Google to use the analytics information received for other Google services.',
  'privacy.s4.p2':
    'The information that Google collects will be made anonymous insofar as is possible. Your IP address is expressly not shared. The information transferred to and by Google is stored on servers in the United States of America. Google undertakes to respect the Privacy Shield principles and is a member of the U.S. Trade and Development Agency’s Privacy Shield programme. This means that there is an appropriate level of protection for the processing of any personal data.',
  'privacy.s5.title': 'YouTube',
  'privacy.s5.p':
    "We embed videos from our official YouTube channel using YouTube's privacy-enhanced mode. This mode may set cookies on your computer once you click on the YouTube video player, but YouTube will not store personally-identifiable cookie information for playbacks of embedded videos using the privacy-enhanced mode. To find out more please visit YouTube's embedding videos information page.",
  'privacy.s6.title': 'Facebook',
  'privacy.s6.p':
    'The American company, Facebook, installs a cookie via our website as a part of its ‘Facebook Pixel’ service. We use this service to receive updated reports on how visitors use our website and to display targeted advertisements on Facebook. A user profile is built during your visit to this website. Facebook can provide this information to third parties if Facebook is legally required to do so, or if these third parties process the information for Facebook.',
  'privacy.s6.p2':
    'The information transferred to and by Facebook is stored on servers in the United States of America for an indeterminate period.',
  'privacy.s7.title': 'Activating, deactivating, and deleting cookies',
  'privacy.s7.p':
    'You can find more information about activating, deactivating, and removing cookies in the instructions and/or by using your browser’s Help function.',
  'privacy.privacy.title': 'Privacy Policy',
  'privacy.privacy.p':
    'We are well aware that you are putting your faith in us, which is why we consider it our responsibility to protect your privacy. We will tell you on this page what information we collect when you use our website, why we collect this information, and how we use it to improve your user experience. This way, you understand exactly how we work.',
  'privacy.s9.title': 'Use of our services',
  'privacy.s9.p':
    'When you log in for one of our services, we ask you to provide some personal information. This information is used to be able to carry out the service. The information is stored on our own secure servers. We will not combine this information with other personal information that we may have.',
  'privacy.s10.title': 'Communication',
  'privacy.s10.p':
    'When you send us an email or other messages, we may save these messages. We may occasionally request personal information that is relevant to the situation in question. This makes it possible to process your requests and answer your questions. The information is stored on our own secure servers. We will not combine this information with other personal information that we may have.',
  'privacy.s11.title': 'Purposes',
  'privacy.s11.p':
    'We do not collect or use any information for purposes other than those purposes that are described in this Privacy Policy unless we have received your permission to do so beforehand.',
  'privacy.s12.title': 'Third parties',
  'privacy.s12.p':
    'The information is not shared with third parties. There are certain cases where the information may be shared internally. Our employees are required to respect the confidentiality of your information.',
  'privacy.s13.title': 'Intellectual property',
  'privacy.s13.p':
    'The texts, images and other items on this website are protected by copyright. If you wish to reproduce them or communicate them to the public, you must obtain written permission to do so, unless this is not necessary according to the provisions of the law on copyright and related rights. ',
  'privacy.s14.title': 'Changes',
  'privacy.s14.p':
    'This Privacy Policy is harmonised with the use and the functions on this website. Any changes and/or amendments to this site can lead to changes in this Privacy Policy. Therefore, we recommend that you regularly check this Privacy Policy.',
}
